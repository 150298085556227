import React from "react";
import desk from "../assets/desk.jpg";
export default class Conclution extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          margin: "15px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "50%",
            }}
          >
            <h1 style={{ margin: "-15px" }}>Grow Your Sales</h1>
            <h2>
              With our team as your seller on Amazon, you’re able to leverage
              our expertise and resources to dramatically increase your revenue.
              Our suppliers generally see a{" "}
              <div
                style={{
                  fontSize: "37px",
                  color: "deepskyblue",
                  marginTop: "-1px",
                }}
              >
                10-30% Increase in the First 6 Months!
              </div>
            </h2>
            <h1 style={{ margin: "-15px" }}>Protect Your Brand</h1>
            <h2>
              We successfully assist in removing unauthorized sellers and MAP
              breakers that cause havoc with your brand on Amazon.
            </h2>
          </div>
        </div>
        <div
          style={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            width: "35%",
          }}
        >
          <img src={desk} />
        </div>
      </div>
    );
  }
}

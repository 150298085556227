import React from "react";
import warehouse from "../assets/warehouse.png";
import { render } from "@testing-library/react";
export default class Market extends React.Component {
  render() {
    return (
      <div
        style={{
          background: "rgba(76, 175, 80, 0.3)",
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          backgroundImage: `url(${warehouse})`,
          height: "500px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <div
            style={{
              backgroundColor: "#fff",
              width: "30%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // borderRadius: "15px",
              backgroundColor: "#fff",
              border: "solid",
              borderWidth: "1px",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <b>MARKETPLACE</b>
              <b> MANAGEMENT</b>
            </div>
            <ul style={{ margin: "15px" }}>
              <li>Upfront Inventory Purchase by K-6 Solutions </li>
              <li>MAP Policy Creation and Enforcement</li>
              <li>Seller Central Account Management</li>
              <li>
                Content Creation (Enhanced Brand Content, Images, Copywriting,
                etc.)
              </li>
              <li> Ranking Improvements via Data-driven SEO</li>
              <li> Amazon Advertising Management</li>
            </ul>
          </div>
          <div
            style={{
              backgroundColor: "#fff",
              width: "30%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // borderRadius: "15px",
              backgroundColor: "#fff",
              border: "solid",
              borderWidth: "1px",
            }}
          >
            <div
              style={{
                fontSize: "30px",
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <b>MANAGED</b>
              <b>SERVICES</b>
            </div>
            <ul style={{ margin: "15px" }}>
              <li>Seller Central</li> <li>Account Management</li>{" "}
              <li>
                Content Creation (Enhanced Brand Content, Images, Copywriting,
                etc.)
              </li>
              <li>Ranking Improvements via Data-driven SEO</li>
              <li> Amazon Advertising Management</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import dither from "../assets/dither.jpg";
import points from "../assets/points.png";
import review from "../assets/Review.png";
import PicModal from "./PicModal";
export default class Know extends React.Component {
  render() {
    return (
      <div
        id="right"
        style={{
          width: "100%",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundImage: `url(${dither})`,
            height: "300px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            color: "#fff",
            textAlign: "center",
            fontSize: "3em",
          }}
        >
          WHAT YOU ABSOLUTELY MUST GET RIGHT
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "35%",
              textAlign: "center",
              dispaly: "flex",
              justifyContent: "center",
            }}
          >
            <div>
              <h1>WHAT YOU ABSOLUTELY MUST GET RIGHT</h1>
              <b>
                <div
                  style={{
                    fontSize: "2em",
                    dispaly: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <div style={{ marginBottom: "15px" }}>Pictures</div>
                  <div style={{ marginBottom: "15px" }}>Bullet Point</div>
                  <div style={{ marginBottom: "15px" }}>Product Reviews</div>
                  <div>Responding To Negative Reviews</div>
                </div>
              </b>
              <h3 style={{ marginBottom: "-15px" }}>
                Pictures are Selling Sheets
              </h3>
              <p>
                Nothing is more powerful than having high-quality pictures that
                provide all the relevant information needed to make a purchase
                decision.
              </p>
              <p>K-6 Solutions will make this happen for you. </p>
              <p
                style={{ width: "97%", textAlign: "center", margin: "0 1.5%" }}
              >
                Here is a perfect example of how a seller uses key pieces of
                information along with high-quality images to inform the buyer
                and create a positive experience with the product.
                <PicModal
                  img={
                    "https://images-na.ssl-images-amazon.com/images/I/81i0MDc9K2L._AC_SL1500_.jpg"
                  }
                  styles={{ width: "100%", margin: "15px" }}
                ></PicModal>
              </p>
              <h3 style={{ marginBottom: "-15px" }}>
                Bullet Points Should Showcase Benefits, Not Just Features
              </h3>
              <p>
                Bullet points for many product listings are far too short and
                focus only on the features of the product instead of the
                benefits the product will give a customer.
              </p>
              <p>
                K-6 Solutions utilizes expert Amazon writers to make this happen
                for you.
              </p>
              <p
                style={{ width: "97%", textAlign: "center", margin: "0 1.5%" }}
              >
                Here’s a great example of how a seller maximized the space
                available to help the buyer understand the many benefits of
                their product.
                <PicModal img={points} styles={{ margin: "15px" }}></PicModal>
              </p>
              <h3 style={{ marginBottom: "-15px" }}>
                Automatically Generate More Product Reviews
              </h3>
              <p>
                Product reviews make or break a product on Amazon. Reviews also
                impact product ranking in search algorithms. 92% of customers
                are more likely to purchase a product after reading a positive
                review.
              </p>
              <p
                style={{ width: "97%", textAlign: "center", margin: "0 1.5%" }}
              >
                K-6 Solutions uses an email autoresponder system to ask all
                buyers for a product review. Easy to do but few, if any, of your
                product sellers do this today.
                <PicModal
                  img={review}
                  styles={{ margin: "15px", width: "100%" }}
                ></PicModal>
              </p>
              <h3 style={{ marginBottom: "-15px" }}>
                Responding to Negative Reviews
              </h3>
              <p>
                Despite having an excellent product, you may receive a negative
                review.
              </p>
              <p
                style={{ width: "97%", textAlign: "center", margin: "0 1.5%" }}
              >
                K-6 Solutions won’t ignore it. We will immediately respond to
                each negative review and demonstrate to future buyers that
                customer service is a top priority for your brand.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
export default class Market extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "sticky",
          top: 0,
          paddingBottom: "-15px",
          borderBottom: "solid",
        }}
      >
        <div
          onClick={() => document.getElementById("top").scrollIntoView()}
          style={{ width: "400px", marginLeft: "100px", cursor: "pointer" }}
        >
          <h1
            style={{
              margin: 0,
              marginBottom: "-11px",
              padding: 0,
              border: 0,
            }}
          >
            K-6
          </h1>
          <h2
            style={{
              margin: 0,
              padding: 0,
              border: 0,
              color: "deepskyblue",
            }}
          >
            {" SOLUTIONS"}
          </h2>
        </div>
        <div
          style={{ width: "30%", display: "flex", justifyContent: "center" }}
        ></div>
        <div
          style={{
            width: "50%",
            display: "flex",
            justifyContent: "space-around",
            color: "carcoal",
          }}
        >
          <h3
            style={{ cursor: "pointer" }}
            onClick={() =>
              document.getElementById("conclusion").scrollIntoView()
            }
          >
            CONCLUSION
          </h3>
          <h3
            style={{ cursor: "pointer" }}
            onClick={() => document.getElementById("right").scrollIntoView()}
          >
            MUST GET RIGHT
          </h3>
          <h3
            style={{ cursor: "pointer" }}
            onClick={() => document.getElementById("contact").scrollIntoView()}
          >
            CONTACT
          </h3>
        </div>
      </div>
    );
  }
}

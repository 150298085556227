import React from "react";
import dither from "../assets/dither.jpg";
import seoppc from "../assets/seoppc.jpg";
import stop from "../assets/stop.jpg";
import keyword from "../assets/keyword.png";
import PicModal from "./PicModal";
export default class Know extends React.Component {
  render() {
    return (
      <div
        style={{
          width: "100%",
          marginBottom: "50px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            backgroundImage: `url(${dither})`,
            height: "300px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            color: "#fff",
            textAlign: "center",
            fontSize: "3em",
          }}
        >
          ADDITIONAL WAYS TO CREATE SUCCESS
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "35%",
              textAlign: "center",
              dispaly: "flex",
              justifyContent: "center",
            }}
          >
            <h1>ADDITIONAL WAYS TO CREATE SUCCESS</h1>
            <ol
              style={{
                fontSize: "2em",
                dispaly: "flex",
                justifyContent: "center",
              }}
            >
              <b>
                <div style={{ marginBottom: "15px" }}>
                  Drive Revenue With PPC Advertising Campaigns
                </div>
                <div style={{ marginBottom: "15px" }}>
                  Increase Visibility With Key-Word Search
                </div>
                <div>Identify, Report, And Remove MAP Violators</div>
              </b>
            </ol>
            <h3 style={{ marginBottom: "-15px" }}>
              Drive Revenue with PPC Advertising Campaigns
            </h3>
            <p>
              SEO & PPC work in tandem on Amazon to drive product exposure and
              increased revenues. When you have multiple sellers on each one of
              your product listings, it is unlikely that any of them is
              utilizing this powerful tool and your sales are suffering as a
              result.
            </p>
            <p>
              K-6 Solutions understands how to manipulate this powerful
              combination to maximize product exposure and drive new sales.
            </p>
            <img src={seoppc} />
            <h3 style={{ marginBottom: "-15px" }}>
              Increase Visibility with Key-Word Search.
            </h3>
            <p>
              Today over 50% of product research on the internet starts with an
              Amazon search. As a result, it’s critical to optimize your listing
              to show up for as many of these search terms as possible and it’s
              unlikely that your current sellers are doing this for you.
            </p>
            <img src={keyword} />
            <p style={{ width: "97%", textAlign: "center", margin: "0 1.5%" }}>
              K-6 Solutions has proven experience with key-word search
              optimization and through our partnership you will see an immediate
              lift in organic sales.
            </p>
            <h3 style={{ marginBottom: "-15px" }}>
              Identify, Report, and Remove MAP Violators.
            </h3>
            <p>
              Most companies have a MAP policy and are all too familiar with the
              frustration that comes with the weakening of their brand’s
              presence when MAP is violated.
            </p>
            <p>
              K-6 Solutions works together with the brand to successfully remove
              MAP violators.
            </p>
            <img src={stop} styles={{ width: "100%" }} />
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
import money from "../assets/money.jpg";
import green from "../assets/green.jpg";
export default class Conclution extends React.Component {
  render() {
    return (
      <div
        id="conclusion"
        style={{
          width: "100%",

          backgroundImage: `url("https://cdn.wallpapersafari.com/38/19/Gx7iyR.jpg")`,
          borderTop: "solid",
          borderBottom: "solid",
          paddingBottom: "15px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            color: "#fff",
          }}
        >
          <b style={{ fontSize: "33px", margin: "10px" }}>CONCLUSION</b>
          <b style={{ fontSize: "22px", margin: "0 15px 15px 15px" }}>
            You’re leaving money on the table if you don’t have a complete
            Amazon eCommerce strategy.
          </b>
          <div style={{ width: "50%" }}>
            <img src={money} style={{ float: "left", margin: "0 15px" }} />
            <div>
              K-6 Solutions is your selling partner with the end-to-end solution
              for sending your sales through the roof.
            </div>
            <p>
              We are a digital retail agency with significant expertise in the
              Amazon marketplace. We’re not like typical marketing agencies who
              will charge you thousands of dollars in fees.{" "}
              <b>
                We earn our income by purchasing your products wholesale and
                then reselling them - thereby ensuring that our interests are
                100% aligned with yours.
              </b>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

import React from "react";
export default class PicModal extends React.Component {
  state = { closed: true };
  render() {
    return (
      <div>
        {this.state.closed ? (
          <img
            onClick={() => this.setState({ closed: false })}
            src={this.props.img}
            style={{
              ...this.props.styles,
              cursor: "pointer",
            }}
          />
        ) : (
          <div
            style={{
              width: window.innerWidth,
              height: "100%",
              zIndex: 999,
              position: "absolute",
              left: 0,
              marginTop: "25px",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                zIndex: 999,
                position: "sticky",
                top: 0,
                left: 0,
              }}
            >
              <div
                style={{ margin: "15px" }}
                onClick={() => this.setState({ closed: true })}
              >
                <b
                  style={{
                    border: "solid",
                    borderRadius: "100%",
                    padding: "5px",
                    cursor: "pointer",
                    zIndex: 1121,
                  }}
                >
                  X
                </b>
              </div>
              <img
                onClick={() => this.setState({ closed: true })}
                src={this.props.img}
                style={{
                  zIndex: 1111,
                }}
                width="100%"
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

import React from "react";
import paperpile from "../assets/paperpile.jpg";
import growth from "../assets/growth.jpg";
import cop from "../assets/cop.png";
import Market from "./Market";
import Know from "./Know";
import Navbar from "./Navbar";
import Info from "./Info";
import Additonal from "./Additional";
import Conclution from "./Conclution";
import road from "../assets/road.jpg";
import wall from "../assets/wall.jpg";

class Home extends React.Component {
  render() {
    return (
      <div
        class="Whole Page"
        style={{ width: "100%", margin: 0, padding: 0, border: 0 }}
      >
        <Navbar></Navbar>
        <div
          id="top"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundImage: `url(${road})`,
            height: "500px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              color: "#fff",
              textAlign: "center",
            }}
          >
            <div style={{ fontSize: "85px", textAlign: "center" }}>
              We Help Your Brands
            </div>
            <div style={{ fontSize: "85px", textAlign: "center" }}>
              Win on Amazon
            </div>
            <div
              style={{
                marginTop: "-15px",
              }}
            >
              <h1>Control Pricing, Remove Rogue Sellers, and Increase Sales</h1>
            </div>
          </div>
        </div>
        <Info></Info>
        <Market></Market>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <div style={{ width: "40%", margin: "2%" }}>
            <h3>WE GROW YOUR SALES</h3>
            <img
              src={`${growth}`}
              style={{ float: "left", margin: "0 15px" }}
            />
            We invest in you. We deploy our talents on your behalf to drive
            traffic to your brand with key-word and search engine optimization
            strategies that will significantly increase your ranking on Amazon.
            This means greater exposure, higher conversion rates, and increased
            sales.
          </div>
          <div style={{ width: "40%", margin: "2%" }}>
            <h3>BRAND PROTECTION</h3>
            <img src={`${cop}`} style={{ float: "left", margin: "0 15px" }} />
            We protect your brand. Counterfeiters and unauthorized sellers on
            Amazon put your brick-and-mortar relationships at risk by limiting
            your control and slowing product growth. Our team of Amazon experts
            understands how to identify and remove unauthorized sellers and MAP
            violators, and repair damage caused.
          </div>
        </div>
        <Know></Know>
        <Additonal></Additonal>
        <Conclution></Conclution>
        <div
          id="contact"
          style={{
            backgroundImage: `url("https://cdn.wallpapersafari.com/38/19/Gx7iyR.jpg")`,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            paddingBottom: "30px",
            borderBottom: "solid",
            color: "#fff",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "40%",
              textAlign: "center",
            }}
          >
            <h1>K-6 Solutions</h1>
            <div>
              We welcome your question, suggestions and feedback on our
              services.
            </div>
            <a href="mailto:shawn@k-6solutions.com?subject=Inquiry&body=Hello I am interested in learning how you can improve my amazon buisness!">
              shawn@k-6solutions.com
            </a>
            <p>
              We strive to reply to every email as quickly as possible. Expect a
              response within 24-48 hours, except weekends and holidays.
              <p>
                Business Hours M-F 9-5 Mountain Time
                <div> Closed Saturday and Sunday</div>
              </p>
              <div>Phone</div>
              <a href="tel:801-842-5402">801-842-5402</a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
